<template>
  <div class="wrap-content">
    <Header @showNav="handleNav"/>
    <noRead v-if="tab == 0" />
    <Read v-if="tab == 1" />

    <Footer />
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";

import bus from "@/utils/bus.js";
import noRead from "./noRead.vue";
import Read from "./read.vue";
import Header from "./Header.vue";
import Footer from "@/components/footer.vue";
let tab = ref(0);
function handleNav(val) {
  tab.value = val;
}

onMounted(() => {
 
});
</script>

<style lang="scss" scoped>
@import "../dataFilling/index.scss";
@import "./index.scss";
</style>