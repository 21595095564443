<template>
    <div class="search">
      <ul>
        <li>
          <Input placeholder="输入名称" v-model="name" class="width-300" />
        </li>

        <li>
          <div class="btn search-btn flex-center-center" @click="handleSearch">
            <div class="img"></div>
          </div>
          <div
            class="btn reset-btn flex-center-center"
            @click="handleReset"
            v-if="name != '' || type != ''"
          >
            <div class="img"></div>
          </div>
        </li>
      </ul>
      <div class="flex">
        <div class="static">
          <span>
            共
            <span class="bold">{{ total }}</span
            >项
          </span>
          <span v-if="selection.length > 0">
            已选
            <span class="bold">{{ selection.length }}</span
            >项
          </span>
        </div>
     
      </div>
    </div>
    <div class="table" :class="tableData.length > 0 ? '' : 'noData'">
      <ul v-if="tableData.length > 0">
        <li
          v-for="(item, i) in tableData"
          :key="item.id"
          @click="handleShow(item,i)"
        >
          <div class="order">{{ i + 1 }}</div>
          <div class="state">
            <span v-if="item.state == 1">已读</span>
            <span class="read" v-if="item.state == 0">未读</span>
          </div>
          <div class="info" :class="item.isShow ? 'open' : ''">
            <p class="title">{{ item.title }}</p>
            <p class="desc" v-show="item.isShow">
             {{ item.msg }}
            </p>
          </div>
          <div class="date">{{ item.updateDate }}</div>
          <Icon type="ios-arrow-forward" v-if="!item.isShow" />
          <Icon type="ios-arrow-down" v-if="item.isShow" />
         
        </li>
      </ul>
      <noData v-else />
    </div>
    <div class="page" v-if="total > 10">
      <Page :total="total" show-elevator show-total @on-change="changePage" />
    </div>
</template>

<script setup>
import { ref, reactive, inject, onMounted } from "vue";
import { Message } from "view-ui-plus";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
import { Modal } from "view-ui-plus";
import Header from "./Header";
import Footer from "@/components/footer.vue";
import noData from "@/components/noData.vue";
import qs from "qs";
let name = ref("");
let type = ref("");

let tableData = ref([]);
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);
let selection = ref([]);
let router = useRouter();

function changePage(val) {
  page.value = val;
  getTableData();
}
let axios = inject("axios");
const getTableData = () => {
  let url = `/message/getAuditLogs`;
  let params = {
    page: page.value - 1,
    size: pageSize.value,
    dataName: name.value,
    status:0
  };

  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      tableData.value = data.data;
      total.value = data.total;
    }
  });
};

function handleSearch() {
  page.value = 1;
  getTableData();
}

//复核
const audit = (row) => {
  updateStatus(row);
  //跳转复核，一般数据不需要复核
  if (row.level == "重要数据") {
    router.push({
      name: "dataDetails",
      query: {
        id: row._id,
      },
    });
  } else {
    router.push({
      name: "commonDetail",
      query: {
        id: row._id,
      },
    });
  }
};
//更新消息状态
const updateStatus = (row) => {
  let url = `/message/updateLogStatus?id=${row.id}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      // handleSearch();
      bus.emit("noticeCount");
    }
  });
};

//全部已读
const handleSubmit = () => {
  let url = `/message/updateLogStatus?id=`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      handleSearch();
      bus.emit("noticeCount");
    }
  });
};

//展示与隐藏
const handleShow = (row,i) => {
  tableData.value[i].isShow = !tableData.value[i].isShow;
  updateStatus(row);
};

const handleReset = () => {
  name.value = "";
  type.value = "";
  handleSearch();
};
onMounted(() => {
  handleSearch();
});
</script>

<style lang="scss" scoped>
@import "../dataFilling/index.scss";
@import "./index.scss";
</style>